import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import RenderBlock from './utilities/RenderBlock';
import removeUnderscoreKeys from './utilities/RemoveDataUnderscoreDuplicates';
import { Helmet } from 'react-helmet';

const PAGE_QUERY = gql`
  query GetPageByUri($uri: String!) {
    pageBy(uri: $uri) {
      title
      uri
      content
      blocks {
        innerBlocks {
          name
          ... on AcfAccordionBlock {
            attributes {
              data
            }
          }
          ... on AcfHeroBlock {
            attributes {
              data
            }
          }
          ... on AcfImageAndTextBlock {
            attributes {
              data
            }
          }
          ... on AcfCalendarBlock {
            attributes {
              data
            }
          }
          ... on AcfFeaturedImageBlock {
            attributes {
              data
            }
          }
          ... on AcfFeaturedEventsBlock {
            attributes {
              data
            }
          }
          ... on AcfFullImageCardGridBlock {
            attributes {
              data
            }
          }
          ... on AcfMapBlock {
            attributes {
              data
            }
          }
          ... on AcfPostGridBlock {
            attributes {
              data
            }
          }
          ... on AcfPostContentBlock {
            attributes {
              data
            }
          }
          ... on AcfSimpleTextContentBlock {
            attributes {
              data
            }
          }
          ... on AcfSimpleWysiwygBlock {
            attributes {
              data
            }
          }
          ... on AcfSlimHeaderAndContentBlock {
            attributes {
              data
            }
          }
          ... on AcfTwoColumnActionBlock {
            attributes {
              data
            }
          }
          ... on AcfUpcomingEventsBlock {
            attributes {
              data
            }
          }
          ... on AcfSearchBlock {
            attributes {
              data
            }
          }
        }
        name
        ... on AcfSectionBlock {
          attributes {
            data
          }
        }
      }
      seo {
        metaDesc
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        schema {
          articleType
          pageType
          raw
        }
        title
        twitterDescription
        twitterImage {
          sourceUrl
        }
        twitterTitle
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        readingTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphImage {
          sourceUrl
        }
        canonical
      }
    }
  }
`;

const Page = () => {
  const location = useLocation();
  console.log("location")
  console.log(location)
  const { data, loading, error } = useQuery(PAGE_QUERY, {
    variables: { uri: location.pathname }
  });

  if (loading) return <div className="loading"><div className="loading-inner"><div className="loader"></div></div></div>;
  if (error) return <p>Error: {error.message}</p>;

  let seo = {};
  let blocks = [];
  if (data && data.pageBy) {
    if (data.pageBy.seo) {
      seo = data.pageBy.seo;
    }
    if (data.pageBy.blocks) {
      blocks = data.pageBy.blocks;
    }
  }

  const {
    metaDesc = '',
    metaRobotsNoindex = 'index',
    metaRobotsNofollow = 'follow',
    opengraphUrl = '',
    opengraphType = '',
    opengraphTitle = '',
    opengraphSiteName = '',
    opengraphImage = '',
    opengraphPublisher = '',
    opengraphPublishedTime = '',
    opengraphModifiedTime = '',
    twitterDescription = '',
    twitterImage = {},
    twitterTitle = '',
    canonical = '',
    title = '',
    readingTime = '',
  } = seo;

  return (
    <div>
      <div className="page-content">
        <Helmet>
          <title>{title}</title>
          {metaDesc && <meta name="description" content={metaDesc} />}
          <meta name="robots" content={`${metaRobotsNoindex},${metaRobotsNofollow}`} />
          {opengraphUrl && <meta property="og:url" content={opengraphUrl} />}
          {opengraphType && <meta property="og:type" content={opengraphType} />}
          {opengraphImage && 
            opengraphImage.sourceUrl && 
             <meta property="og:type" content={opengraphType} />
          }
          {opengraphTitle && <meta property="og:title" content={opengraphTitle} />}
          {opengraphSiteName && <meta property="og:site_name" content={opengraphSiteName} />}
          {opengraphPublisher && <meta property="og:publisher" content={opengraphPublisher} />}
          {opengraphPublishedTime && <meta property="og:published_time" content={opengraphPublishedTime} />}
          {opengraphModifiedTime && <meta property="og:modified_time" content={opengraphModifiedTime} />}
          {twitterDescription && <meta name="twitter:description" content={twitterDescription} />}
          {twitterImage &&
            twitterImage.sourceUrl &&
              <meta name="twitter:image" content={twitterImage.sourceUrl} />
          }
          {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
          {canonical && <link rel="canonical" href={canonical} />}
          {readingTime && <meta name="readingTime" content={readingTime} />}
          </Helmet>
          {blocks.map((section, index) => {
            let color = '';
            if (section.attributes) {
              const attributes = removeUnderscoreKeys(JSON.parse(section.attributes.data));
              if (attributes) {
                color = attributes.section_theme_color_option;
              }
            }
            return (
              <div key={index} className={"section " + color}>
                {section.innerBlocks.map((block, idx) => {
                  return <RenderBlock key={idx} block={block} />
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
export default Page;