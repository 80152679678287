import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function makeRelativeURL(absoluteURL) {
    try {
        const url = new URL(absoluteURL);
        return url.pathname;
    } catch (error) {
        console.error("Invalid URL:", absoluteURL);
        return "/"; // Fallback to home if URL is invalid
    }
}

const MenuItem = ({ data, onMenuItemClick }) => {
    const location = useLocation();
    let relativeURL = makeRelativeURL(data.url);
    const isActive = location.pathname.startsWith(relativeURL);

    const handleClick = () => {
        if (onMenuItemClick) {
            onMenuItemClick();
        }
        window.scrollTo(0, 0);
    };

    return (
        <li className={isActive ? 'active' : ''}>
            <Link to={relativeURL} onClick={handleClick}>{data.label}</Link>
        </li>
    );
}

export default MenuItem;