import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageWithFadeIn from './ImageWithFadeIn';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


const TransformImagesData = ({ data }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!data || data.length === 0) {
            setLoading(false);
            return;
        }

        const fetchImages = async () => {
            try {
                const idsArray = Array.isArray(data) ? data : [data];
                const response = await axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?action=get_media_items2`,
                JSON.stringify({ ids: idsArray }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
        
                if (response.data.success) {
                    setImages(response.data.data);
                } else {
                    setError(response.data.data);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, [data]);

    if (loading) return <div></div>;
    if (error) return <p>Error: {error}</p>;
    if (images.length === 0) return <p>No data found</p>;

    return (
        <div className="media-items">
            {images.map(item => (
                <div key={item.mediaItemId} className="media-items__image">
                    <ImageWithFadeIn src={item.sourceUrl} alt={item.altText} />
                </div>
            ))}
        </div>
    );
};

export default TransformImagesData;