import React from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates'
import TransformImagesData from '../utilities/TransformImagesData';

const FeaturedImageBlock = ({ block }) => {
    const attributes = removeUnderscoreKeys(JSON.parse(block.attributes.data));
    console.log("FeaturedImageBlock")
    console.log(attributes);
    return (
        <div className="featured-image-block">
            <TransformImagesData data={attributes.featured_image_image} />
        </div>
    );
}

export default FeaturedImageBlock;