import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery, gql } from '@apollo/client';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import ImageWithFadeIn from '../utilities/ImageWithFadeIn'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function simplifySettings(object) {
    // Initialize an empty object to hold the cleaned data
    let simplifiedObject = {};
  
    // Loop through each key in the input object
    for (let key in object) {
        // Check if the current property is an object and has a nested structure we expect
        if (typeof object[key] === 'object' && object[key].hasOwnProperty('node')) {
            // Directly assign the sourceUrl to the corresponding key in the simplified object
            simplifiedObject[key] = object[key].node.sourceUrl;
        }
    }
  
    return simplifiedObject;
  }
  

const MENUS_QUERY = gql`
  query GetMenus {
    globalSettings {
      globals {
        settings {
          social_icons {
            name
            url
            svg
          }
          themeOptions {
            primary
            senary
          }
          blocksettings {
            cardArt {
              node {
                sourceUrl
              }
            }
            desktopHeroSingleColumnImageSeparator {
              node {
                sourceUrl
              }
            }
            desktopTwoColumnImageSepatator {
              node {
                sourceUrl
              }
            }
            twoColumnActionBlockInnerContainerImageLeft {
              node {
                sourceUrl
              }
            }
          }
        }
        themeOptions
      }
    }
  }
`;

const UpcomingEventsBlock = ({ block }) => {
    const { data } = useQuery(MENUS_QUERY);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const attributes = removeUnderscoreKeys(JSON.parse(block.attributes.data));
    const globalSettings = data ? data.globalSettings.globals.settings : null;
    const blockSettings = globalSettings ? simplifySettings(globalSettings.blocksettings) : null;

    console.log("UpcomingEventsBlock");
    console.log(attributes);

    useEffect(() => {
        axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?action=get_upcoming_events_2`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            setEvents(response.data); // Save the response data to state
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching events:', error);
            setError(error);
            setLoading(false);
        });
    }, []); // The empty array ensures this effect runs only once after the component mounts.


    // Extract data and render as needed
    return (
        <div className="upcoming-events-block">
            {attributes.upcoming_events_header && React.createElement(
                attributes.upcoming_events_header_seo || 'div',
                { className: 'header' },
                attributes.upcoming_events_header
            )}
            {loading ? (
                <p>Loading events...</p>
            ) : error ? (
                <p>Error loading events: {error.message}</p>
            ) : (
                <div className="upcoming-events-block-inner">
                    {events.length > 0 && (
                        <div className="upcoming-events-primary">
                          <div style={{background: '#c6c6c6'}}>
                            <ImageWithFadeIn src={events[0].image} alt={''} />
                            </div>
                            <div className="upcoming-events-primary-details">
                                {blockSettings &&
                                    blockSettings.cardArt &&
                                    <img src={blockSettings.cardArt}  alt=" " className="anchor-image-top"/>
                                }
                                <h3 
                                  className="pp-grafier upcoming-events-primary-details__title"
                                  dangerouslySetInnerHTML={{ __html: events[0].title }} 
                                />
                                <p>
                                    {events[0].location}<br/>
                                    {events[0].date} {events[0].times}
                                </p>
                                <a href={events[0].link} className="event-detail-action-secondary btn">See event details</a>
                            </div>
                        </div>
                    )}
                    <div className="upcoming-events-secondary">
                        <h3 className="moderat-title upcoming-events-secondary__title">What's Happening Next at North Loop Green</h3>
                        {events.slice(1).map((event, index) => (
                            <div key={index} className="event-list-box">
                                <div className="date-box pp-grafier">
                                    {event.date}
                                </div>
                                <div className="upcoming-events-secondary-details">
                                    <h4 className="pp-grafier event-list-box__title" dangerouslySetInnerHTML={{ __html: event.title }} />
                                    <p>{event.location}<br/>{event.times}</p>
                                    <a href={event.link} className="event-detail-action-secondary">See event details
                                    <span><svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM18.3536 4.35355C18.5488 4.15829 18.5488 3.84171 18.3536 3.64645L15.1716 0.464466C14.9763 0.269204 14.6597 0.269204 14.4645 0.464466C14.2692 0.659728 14.2692 0.976311 14.4645 1.17157L17.2929 4L14.4645 6.82843C14.2692 7.02369 14.2692 7.34027 14.4645 7.53553C14.6597 7.7308 14.9763 7.7308 15.1716 7.53553L18.3536 4.35355ZM1 4.5H18V3.5H1V4.5Z" fill="#0D7D3F"/>
                                    </svg>
                                    </span>
                                    </a>
                                </div>
                            </div>
                        ))}
                        <div className="button-wrapper">
                            <a className="btn" href="/events/">
                                View Full Events Calendar
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UpcomingEventsBlock;
