import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const GetSlidesForSlider = ({ data, show }) => {
    const sliderRef = useRef(null);
    const [images, setImages] = useState([]); // State to hold fetched images
    const [allLoaded, setAllLoaded] = useState(false);
    const [loadCount, setLoadCount] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        touchThreshold: 10,
        slidesToShow: show,
        slidesToScroll: 1
    };

    useEffect(() => {
        if (!data || data.length === 0) {
            setAllLoaded(true); // If no data provided, set allLoaded to true
            return;
        }

        const fetchImages = async () => {
            try {
                const idsArray = Array.isArray(data) ? data : [data];
                const response = await axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?action=get_media_items2`, {
                    ids: idsArray
                });

                if (response.data.success) {
                    setImages(response.data.data); // Set the fetched images to state
                } else {
                    console.error('Error fetching images:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [data]);

    useEffect(() => {
        if (images.length > 0 && loadCount === images.length) {
            setAllLoaded(true);
        }
    }, [loadCount, images]);

    const handleImageLoad = () => {
        setLoadCount(prev => prev + 1);
    };

    if (!data || data.length === 0) {
        return <p>No images provided</p>;
    }

    return (
        <Slider ref={sliderRef} {...settings}>
            {images.map(item => (
                <div key={item.mediaItemId} className="media-items__image">
                    <img
                        src={item.sourceUrl}
                        alt={item.altText}
                        onLoad={handleImageLoad}
                    />
                </div>
            ))}
        </Slider>
    );
};

export default GetSlidesForSlider;
