import React, { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import TransformImagesData from '../utilities/TransformImagesData';

const SEARCHQUERY = gql`
query Search($searchTerm: String!) {
  pages(where: {search: $searchTerm}) {
    nodes {
      title
      uri
      content
    }
  }
  events(where: {search: $searchTerm}) {
    nodes {
      title
      uri
      content
    }
  }
  amenities(where: {search: $searchTerm}) {
    nodes {
      title
      uri
      content
    }
  }
}
`;

const SearchBlock = ({ block }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [executeSearch, { data, loading, error }] = useLazyQuery(SEARCHQUERY);
  
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    executeSearch({ variables: { searchTerm } });
  };

  const attributes = removeUnderscoreKeys(JSON.parse(block.attributes.data));
  console.log("SearchBlock", attributes);

  return (
    <div className="search-block">
      <div className="inner">
        <form onSubmit={handleFormSubmit}>
          <input 
            type="text" 
            value={searchTerm} 
            onChange={handleInputChange} 
            placeholder="Search..."
          />
          <button type="submit">Search</button>
        </form>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {data && (
          <div className="search-results">
            <h2>Search Results</h2>
            <ul>
              {data.pages.nodes.map((node) => (
                <li key={node.uri}>
                  <a href={node.uri}>{node.title}</a>
                </li>
              ))}
              {data.events.nodes.map((node) => (
                <li key={node.uri}>
                  <a href={node.uri}>{node.title}</a>
                </li>
              ))}
              {data.amenities.nodes.map((node) => (
                <li key={node.uri}>
                  <a href={node.uri}>{node.title}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBlock;
