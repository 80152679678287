import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import AccordionBlock from '../components/Accordion';
import HeroBlock from '../components/Hero';
import ImageAndTextBlock from '../components/ImageAndText';
import CalendarBlock from '../components/Calendar';
import FeaturedImageBlock from '../components/FeaturedImage';
import FeaturedEventsBlock from '../components/FeaturedEvents';
import FullImageCardGridBlock from '../components/FullImageCardGrid';
import MapBlock from '../components/Map';
import PostGridBlock from '../components/PostGrid';
import PostContentBlock from '../components/PostContent';
import SimpleTextContentBlock from '../components/SimpleTextContent';
import SimpleWysiwygBlock from '../components/SimpleWysiwyg';
import SlimHeaderAndContentBlock from '../components/SlimHeaderAndContent';
import TwoColumnActionBlock from '../components/TwoColumnAction';
import UpcomingEventsBlock from '../components/UpcomingEvents';
import SearchBlock from '../components/Search';

const RenderBlock = ({ block, idx }) => {
    const blockRef = useRef(null);

    useEffect(() => {
        const element = blockRef.current;

        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const isMobile = window.matchMedia('(max-width: 767px)').matches;
                    const offset = isMobile ? 50 : 150;

                    gsap.fromTo(element,
                        { opacity: 0 },
                        {
                            opacity: 1,
                            duration: 1,
                            ease: 'power1.inOut',
                            scrollTrigger: {
                                trigger: element,
                                start: `top bottom+=${offset}`,
                                end: `top bottom+=${offset * 2}`,
                                scrub: true
                            }
                        }
                    );
                    observer.unobserve(element);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1 // Adjust this value to control when the animation triggers
        });

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, []);

    const getBlockComponent = () => {
        switch (block.__typename) {
            case 'AcfAccordionBlock':
                return <AccordionBlock block={block} />;
            case 'AcfHeroBlock':
                    return <HeroBlock block={block} />;
            case 'AcfImageAndTextBlock':
                return <ImageAndTextBlock block={block} />;
            case 'AcfCalendarBlock':
                return <CalendarBlock block={block} />;
            case 'AcfFeaturedImageBlock':
                return <FeaturedImageBlock block={block} />;
            case 'AcfFeaturedEventsBlock':
                return <FeaturedEventsBlock block={block} />;
            case 'AcfFullImageCardGridBlock':
                return <FullImageCardGridBlock block={block} />;
            case 'AcfMapBlock':
                return <MapBlock block={block} />;
            case 'AcfPostGridBlock':
                return <PostGridBlock block={block} />;
            case 'AcfPostContentBlock':
                return <PostContentBlock block={block} />;
            case 'AcfSimpleTextContentBlock':
                return <SimpleTextContentBlock block={block} />;
            case 'AcfSimpleWysiwygBlock':
                return <SimpleWysiwygBlock block={block} />;
            case 'AcfSlimHeaderAndContentBlock':
                return <SlimHeaderAndContentBlock block={block} />;
            case 'AcfTwoColumnActionBlock':
                return <TwoColumnActionBlock block={block} />;
            case 'AcfUpcomingEventsBlock':
                return <UpcomingEventsBlock block={block} />;
            case 'AcfSearchBlock':
                return <SearchBlock block={block} />;
            default:
                return <p>Unsupported block type</p>;
        }
    };

    const blockTypeClass = block.__typename.toLowerCase().replace('acf', '').replace('block', '').trim();

    return (
        <div ref={blockRef} key={idx} className={`animated-block ${blockTypeClass}`}>
            {getBlockComponent()}
        </div>
    );
};

export default RenderBlock;
