import React from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates'

const SimpleWysiwygBlock = ({ block }) => {
    const attributes = removeUnderscoreKeys(JSON.parse(block.attributes.data));
    console.log("SimpleWysiwygBlock")
    console.log(attributes);

    // Function to parse attributes and return an array of elements
    function parseContent(attributes) {
        let contentArray = [];
        const contentSequence = attributes.content; // This should be an array indicating the type order
        if (!contentSequence) return [];

        for (let i = 0; i < contentSequence.length; i++) {
            const type = contentSequence[i];
            const baseKey = `content_${i}`;

            if (type === 'header') {
                const header = {
                    type: 'header',
                    text: attributes[`${baseKey}_header`],
                    seo: attributes[`${baseKey}_header_seo`] || 'h2', // Default to 'h2' if not specified
                };
                contentArray.push(header);
            } else if (type === 'content') {
                const content = {
                    type: 'content',
                    text: attributes[`${baseKey}_content`]
                };
                contentArray.push(content);
            }
        }
        return contentArray;
    }

    // Render each content block
    const renderContent = (contentItems) => {
        return contentItems.map((item, index) => {
            if (item.type === 'header') {
                const Tag = item.seo; // Dynamic tag based on SEO needs
                return <Tag key={index} className="heading" dangerouslySetInnerHTML={{ __html: item.text }} />;
            } else if (item.type === 'content') {
                return <div key={index} className="body" dangerouslySetInnerHTML={{ __html: item.text }} />;
            }
            return null;
        });
    };

    // Get the parsed content
    const contentItems = parseContent(attributes);

    return (
        <div className="simple-wysiwyg-block">
            {renderContent(contentItems)}
        </div>
    );
}

export default SimpleWysiwygBlock;
