import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import TransformImagesData from '../utilities/TransformImagesData';

function simplifySettings(object) {
    // Initialize an empty object to hold the cleaned data
    let simplifiedObject = {};
  
    // Loop through each key in the input object
    for (let key in object) {
        // Check if the current property is an object and has a nested structure we expect
        if (typeof object[key] === 'object' && object[key].hasOwnProperty('node')) {
            // Directly assign the sourceUrl to the corresponding key in the simplified object
            simplifiedObject[key] = object[key].node.sourceUrl;
        }
    }
  
    return simplifiedObject;
}

const MENUS_QUERY = gql`
  query GetMenus {
    globalSettings {
      globals {
        settings {
          social_icons {
            name
            url
            svg
          }
          themeOptions {
            primary
            senary
          }
          blocksettings {
            cardArt {
              node {
                sourceUrl
              }
            }
            desktopHeroSingleColumnImageSeparator {
              node {
                sourceUrl
              }
            }
            desktopTwoColumnImageSepatator {
              node {
                sourceUrl
              }
            }
            twoColumnActionBlockInnerContainerImageLeft {
              node {
                sourceUrl
              }
            }
          }
        }
        themeOptions
      }
    }
  }
`;

const HeroBlock = ({ block }) => {
    const { data, loading, error } = useQuery(MENUS_QUERY);
    const attributes = removeUnderscoreKeys(JSON.parse(block.attributes.data));
    const globalSettings = data ? data.globalSettings.globals.settings : null;
    const blockSettings = globalSettings ? simplifySettings(globalSettings.blocksettings) : null;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    console.log("HeroBlock");
    console.log(attributes);
    console.log(blockSettings);
    let MaxLogoWidth = attributes.hero_logo_max_width;
    let LogoHeight = attributes.hero_logo_height;
    let DesktopMaxHeight = attributes.hero_max_desktop_height;

    // Function to render buttons dynamically based on the count in hero_buttons
    const renderButtons = () => {
        let buttons = [];
        for (let i = 0; i < attributes.hero_buttons; i++) {
            const buttonKey = `hero_buttons_${i}_button`;
            const buttonData = attributes[buttonKey];
            const secondaryKey = `hero_buttons_${i}_secondary`;
            const secondaryStyle = attributes[secondaryKey];
            if (buttonData) {
                buttons.push(
                    <a key={i} className={secondaryStyle == "1" ? 'btn secondary' : 'btn'} href={buttonData.url} target={buttonData.target || "_self"} style={{ margin: '5px' }}>
                        {buttonData.title}
                    </a>
                );
            }
        }
        return buttons;
    };

    return (
        <div 
            className={`hero ${attributes.hero_type} ${attributes.hero_dark_ui == "1" ? 'dark' : ''} ${windowWidth > 1250 && DesktopMaxHeight ? 'max-height-set' : ''}`}
        >
            <div className="hero__inner">
                { attributes.hero_type == "two_column" &&
                    <div className="grid-wrapper" style={{ maxHeight: windowWidth > 1250 ? `${DesktopMaxHeight}px` : 'auto', minHeight: windowWidth > 1250 ? `${DesktopMaxHeight}px` : 'auto'  }}>
                        {blockSettings &&
                            blockSettings.desktopTwoColumnImageSepatator &&
                            <img src={blockSettings.desktopTwoColumnImageSepatator}  alt=" " className="anchor-image-middle"/>
                        }
                        <div className="grid lap-one-half">
                            <div className="content">
                                { attributes.hero_desktop_background_art &&
                                    <div className="background-art">
                                        <TransformImagesData data={attributes.hero_desktop_background_art} />
                                    </div>
                                }
                                {attributes.hero_dark_ui != "1" &&
                                  attributes.hero_logo &&
                                  <div className="hero__logo" style={{maxWidth: MaxLogoWidth+"px", height: LogoHeight+"px"}}>
                                    <TransformImagesData data={attributes.hero_logo} />
                                  </div>
                                }
                                {attributes.hero_dark_ui == "1" &&
                                  attributes.hero_logo &&
                                    <div className="hero__logo" style={{maxWidth: MaxLogoWidth+"px", height: LogoHeight+"px"}}>
                                      <TransformImagesData data={attributes.hero_logo_dark} />
                                    </div>
                                }

                                {attributes.hero_header && React.createElement(
                                    attributes.hero_header_seo || 'div',
                                    { className: 'hero-header' },
                                    attributes.hero_header
                                )}

                                {attributes.hero_content && <div className="hero-body">{attributes.hero_content}</div>}


                                <div className="buttons-wrapper">
                                    {renderButtons()}
                                </div>
                            </div>
                        </div>
                        <div className="grid lap-one-half">
                            { attributes.hero_images &&
                                <div className="hero--images-container"><TransformImagesData data={attributes.hero_images} /></div>
                            }
                        </div>
                    </div>
                }
                { attributes.hero_type == "one_column" &&
                    <div className="content-wrapper">
                        {blockSettings &&
                        blockSettings.desktopHeroSingleColumnImageSeparator &&
                        <img src={blockSettings.desktopHeroSingleColumnImageSeparator}  alt=" " className="anchor-image-bottom"/>
                    }
                        <div className="content">
                            {attributes.hero_header && React.createElement(
                                attributes.hero_header_seo || 'div',
                                { className: 'hero-header', dangerouslySetInnerHTML: { __html: attributes.hero_header } }
                            )}

                            {attributes.hero_content && <div className="hero-body">{attributes.hero_content}</div>}


                            <div className="buttons-wrapper">
                                {renderButtons()}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default HeroBlock;