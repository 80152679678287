import React, { useState, useEffect } from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const AccordionBlock = ({ block }) => {
    const [accordionItems, setAccordionItems] = useState([]); // Define content state
    const attributes = removeUnderscoreKeys(JSON.parse(block.attributes.data));
    console.log("Accordion");
    console.log(attributes);

    useEffect(() => {

        const faqIds = [];
        const faqCount = attributes.accordion_accordion_repeater || attributes.accordion_faqs;
        for (let i = 0; i < faqCount; i++) {
            const faqId = attributes[`accordion_faqs_${i}_faq`];
            if (faqId) {
                faqIds.push(faqId);
            }
        }
        if (attributes.accordion_use_faq === "1" ) {
            for (let i = 0; i < faqCount; i++) {
                const faqId = attributes[`accordion_faqs_${i}_faq`];
                if (faqId) {
                    faqIds.push(faqId);
                }
            }
            console.log("ID's")
            console.log(faqIds)

            if (faqIds.length > 0) {
                const params = new URLSearchParams({
                    action: 'get_custom_get_faqs',
                    ids: faqIds.join(',')
                });

                axios.post(`${apiBaseUrl}/wp-admin/admin-ajax.php?${params.toString()}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    const faqsAccordion = response.data.map(faq => ({
                        id: faq.id,
                        title: faq.header,
                        content: faq.body
                    }));
                    console.log(faqsAccordion)
                    setAccordionItems(faqsAccordion);
                }).catch(error => {
                    console.error('Error fetching FAQs:', error);
                });
            }
        } else {
            const manualAccordionItems = [];
            for (let i = 0; i < faqCount; i++) {
                const title = attributes[`accordion_accordion_repeater_${i}_accordion_item_title`];
                const content = attributes[`accordion_accordion_repeater_${i}_accordion_item_body`];
                if (title && content) {
                    manualAccordionItems.push({
                        id: `accordion-item-${i}`,
                        title,
                        content
                    });
                }
            }
            setAccordionItems(manualAccordionItems);
        }
    }, []);


    return (
        <div className="accordion-block">
            <div className="inner">
                {attributes.accordion_header && React.createElement(
                    attributes.accordion_header_seo || 'div',
                    { className: 'accordion-header' },
                    attributes.accordion_header
                )}
                <div className="accordion">
                    {accordionItems &&
                    accordionItems.map((accordion, idx) => (
                    <div key={accordion.id} className="accordion-item">
                        <button 
                        className="accordion-title"
                        aria-expanded="false"
                        aria-controls={accordion.id}
                        onClick={(e) => {
                            const isExpanded = e.currentTarget.getAttribute('aria-expanded') === 'true';
                            e.currentTarget.setAttribute('aria-expanded', !isExpanded);
                            document.getElementById(accordion.id).style.display = isExpanded ? 'none' : 'block';
                        }}
                        >
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13.5" cy="13.5" r="12.75" stroke="#00953C" strokeWidth="1.5"/>
                            <path d="M7 13H21M13.5 20V6" stroke="#01462B" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                        <span>{accordion.title}</span>
                        </button>
                        <div id={accordion.id} className="accordion-content" style={{ display: 'none' }}>
                        <p dangerouslySetInnerHTML={{ __html: accordion.content }} />
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AccordionBlock;