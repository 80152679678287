import React from 'react';
import { useQuery, gql } from '@apollo/client';
import TransformContentData from '../utilities/TransformContentData';
import TransformImagesData from '../utilities/TransformImagesData';
import RenderRichContent from '../utilities/RenderRichContent';
import GetSlidesForSlider from '../utilities/GetSlidesForSlider'

function simplifySettings(object) {
    // Initialize an empty object to hold the cleaned data
    let simplifiedObject = {};
  
    // Loop through each key in the input object
    for (let key in object) {
        // Check if the current property is an object and has a nested structure we expect
        if (typeof object[key] === 'object' && object[key].hasOwnProperty('node')) {
            // Directly assign the sourceUrl to the corresponding key in the simplified object
            simplifiedObject[key] = object[key].node.sourceUrl;
        }
    }
  
    return simplifiedObject;
  }
  

const MENUS_QUERY = gql`
  query GetMenus {
    globalSettings {
      globals {
        settings {
          social_icons {
            name
            url
            svg
          }
          themeOptions {
            primary
            senary
          }
          blocksettings {
            cardArt {
              node {
                sourceUrl
              }
            }
            desktopHeroSingleColumnImageSeparator {
              node {
                sourceUrl
              }
            }
            desktopTwoColumnImageSepatator {
              node {
                sourceUrl
              }
            }
            twoColumnActionBlockInnerContainerImageLeft {
              node {
                sourceUrl
              }
            }
          }
        }
        themeOptions
      }
    }
  }
`;

const ImageAndTextBlock = ({ block }) => {
    const { data, loading, error } = useQuery(MENUS_QUERY);
    const attributes = JSON.parse(block.attributes.data);
    let Images = TransformImagesData(attributes.image_and_text_block_images);
    let contentRepeater = TransformContentData(attributes);

    const globalSettings = data ? data.globalSettings.globals.settings : null;
    const blockSettings = globalSettings ? simplifySettings(globalSettings.blocksettings) : null;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        touchThreshold: 10,
        slidesToShow: 1.05,
        slidesToScroll: 1
    };

    console.log("ImageAndTextBlock")
    console.log(attributes);

    const baseClass = "grid-wrapper";
    const className = `${baseClass} ${attributes.image_and_text_block_reverse_grid === "1" ? 'grid-wrapper--reverse' : ''}`;
    
    return (
        <div className="image-and-text-block">
            <div className={className}>
                <div className="grid left">
                    <div className="inner">
                        { attributes.image_and_text_block_images.length > 1 ? 
                            <div className="slider-wrapper">
                                <GetSlidesForSlider data={attributes.image_and_text_block_images} show={1} /></div>
                            :
                            <div><TransformImagesData data={attributes.image_and_text_block_images}/></div>
                        }
                        {blockSettings &&
                            blockSettings.cardArt &&
                            <img src={blockSettings.cardArt}  alt=" " className="anchor-image-bottom"/>
                        }
                    </div>
                </div>
                <div className="grid right">
                    {contentRepeater.length > 0 &&
                        <div className="content">
                            {contentRepeater.map((block, index) => (
                                <RenderRichContent key={index} block={block} />
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ImageAndTextBlock