import React from 'react';
import removeUnderscoreKeys from '../utilities/RemoveDataUnderscoreDuplicates'
import TransformImagesData from '../utilities/TransformImagesData';
import Tabs from '../utilities/Tabs'

const MapBlock = ({ block }) => {
    const attributes = removeUnderscoreKeys(JSON.parse(block.attributes.data));
    console.log("MapBlock")
    console.log(attributes);

    function extractTransportationOptions(data) {
        const optionsCount = data.map_transportation_options;
        const options = [];
    
        for (let i = 0; i < optionsCount; i++) {
            const titleKey = `map_transportation_options_${i}_title`;
            const iconKey = `map_transportation_options_${i}_icon`;
            const contentKey = `map_transportation_options_${i}_content`;
    
            if (data[titleKey] && data[iconKey] && data[contentKey]) {
                options.push({
                    title: data[titleKey],
                    icon: data[iconKey],
                    content: data[contentKey]
                });
            }
        }
    
        return options;
    }
    
    let tabs = extractTransportationOptions(attributes)
    
    // Extract data and render as needed
    return (
        <div className="map-block">
            <div className="inner">
                <div className="grid-wrapper">
                    <div className="grid lap-one-half">
                        { attributes.map_map_image &&
                            <div><TransformImagesData data={attributes.map_map_image} /></div>
                        }
                    </div>
                    <div className="grid lap-one-half">
                        <div className="content">
                            {attributes.map_title && 
                                <h3 className="map-block__title" dangerouslySetInnerHTML={{ __html :attributes.map_title }} />
                            }
                            {attributes.map_address && 
                                <div className="address">
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.03906 12.2188C4.75781 12.5703 4.21875 12.5703 3.9375 12.2188C2.74219 10.6953 0 7.0625 0 5C0 2.51562 2.01562 0.5 4.5 0.5C6.98438 0.5 9 2.51562 9 5C9 7.0625 6.25781 10.6953 5.03906 12.2188ZM4.5 3.5C3.96094 3.5 3.46875 3.80469 3.1875 4.25C2.92969 4.71875 2.92969 5.30469 3.1875 5.75C3.46875 6.21875 3.96094 6.5 4.5 6.5C5.01562 6.5 5.50781 6.21875 5.78906 5.75C6.04688 5.30469 6.04688 4.71875 5.78906 4.25C5.50781 3.80469 5.01562 3.5 4.5 3.5Z" fill="black"/>
                                    </svg>
                                    <div dangerouslySetInnerHTML={{ __html :attributes.map_address }} />
                                </div>
                            }
                            <Tabs data={tabs} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MapBlock;