import React from 'react';
import { gql, useQuery } from '@apollo/client';

const QUERY = gql`
query GetEvents($slug: String!) {
  eventBy(slug: $slug) {
    eventSettings {
      additionalEventSettings {
        eventRegistrationUrl
        eventRegistrationTextOverride
      }
    }
  }
}
`;

const GetEventACFSettings = () => {
  // Extract slug from the pathname (ignores query parameters)
  const cleanSlug = window.location.pathname.split('/').filter(Boolean).pop();

  console.log("CLEAN SLUG:", cleanSlug);

  const { loading, error, data } = useQuery(QUERY, { 
    variables: { slug: cleanSlug }, 
    skip: !cleanSlug // Prevents query execution if slug is missing
  });

  if (!cleanSlug) {
    return null; // Don't render anything if slug is invalid
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const additionalSettings = data?.eventBy?.eventSettings?.additionalEventSettings;

  if (!additionalSettings?.eventRegistrationUrl) {
    return null; // Don't render anything if registration URL doesn't exist
  }

  return (
    <div className="registration-button">
      <a href={additionalSettings.eventRegistrationUrl} className="button btn">
        {additionalSettings.eventRegistrationTextOverride || 'Register'}
      </a>
    </div>
  );
};

export default GetEventACFSettings;
